.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.container {
	max-width: 100% !important;
}

.stepper {
	background-color: #002853;
}

.stepper2 {
	position: fixed;
	height: 105%;
	z-index: 1;
	padding: 16px;
	width: 85px !important;
}

.main {
	position: relative;
	padding-left: 0px !important;
}

@media screen and (max-width: 576px) {
	.toggle-sidebar-2 {
		position: absolute;
		color: white;
		cursor: pointer;
		background-color: #002853;
		left: 42%;
		width: 100px;
		height: 20px;
		line-height: 15px;
		text-align: center;
		bottom: -16px;
		font-size: 20px;
		z-index: 1;
		border: 1px solid black;
	}
}

@media screen and (min-width: 576px) {
	.toggle-sidebar-2 {
		position: absolute;
		color: white;
		cursor: pointer;
		background-color: #002853;
		right: 70%;
		width: 100px;
		height: 20px;
		line-height: 15px;
		text-align: center;
		bottom: -16px;
		font-size: 20px;
		z-index: 1;
		border: 1px solid black;
	}
}

@media screen and (min-width: 768px) {
	.toggle-sidebar-2 {
		position: absolute;
		color: white;
		cursor: pointer;
		background-color: #002853;
		right: 60%;
		width: 100px;
		height: 20px;
		line-height: 15px;
		text-align: center;
		bottom: -16px;
		font-size: 22px;
		z-index: 1;
		border: 1px solid black;
	}
}

@media screen and (min-width: 900px) {
	.toggle-sidebar-2 {
		position: absolute;
		color: white;
		cursor: pointer;
		background-color: #002853;
		right: -35px;
		width: 20px;
		height: 100px;
		line-height: 100px;
		top: 12%;
		font-size: 20px;
		z-index: 1;
		border: 1px solid black;
	}
}

@media screen and (min-width: 1200px) {
	.toggle-sidebar-2 {
		position: absolute;
		color: white;
		cursor: pointer;
		background-color: #002853;
		right: -40px;
		width: 25px;
		height: 100px;
		line-height: 100px;
		top: 12%;
		font-size: 20px;
		z-index: 1;
		border: 1px solid black;
	}
}

.css-qivjh0-MuiStepLabel-label {
	color: white !important;
}

.stepper path {
	color: #31d84a;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
	width: 32px;
	height: 32px;
}

.step-label {
	color: white !important;
}

* {
	margin: 0;
	box-sizing: border-box;
}

.config {
	position: relative;
}

.content-main {
	position: relative;
	padding-right: 2px;
	overflow-y: auto;
	/* margin-right:-32px; */
	/* scrollbar-width: none; */
}

.content-main::-webkit-scrollbar {
	background: #ddd;
	width: 5px;
}

.content-main::-webkit-scrollbar-track {
	background: #ddd;
}

.content-main::-webkit-scrollbar-thumb {
	background: #000c3a;
}

.content-main::-webkit-scrollbar-thumb:hover {
	background: black;
}

.prev-next-btn {
	display: flex;
	justify-content: space-around;
	position: absolute;
	bottom: 20px;
	width: 100%;
	padding-right: 20px;
}

@media screen and (max-width: 576px) {
	.prev-next {
		width: 288px;
	}

	.content {
		height: 600px;
	}

	.content-main {
		height: 380px;
	}
}

@media screen and (min-width: 576px) {
	.prev-next {
		width: 246px;
	}

	.content {
		height: 600px;
	}

	.content-main {
		height: 390px;
	}
}

@media screen and (min-width: 768px) {
	.prev-next {
		width: 260px;
	}

	.content {
		height: 600px;
	}

	.content-main {
		height: 390px;
	}
}

@media screen and (min-width: 960px) {
	.prev-next {
		width: 330px;
	}

	.content {
		height: 600px;
	}

	.content-main {
		height: 540px;
	}
}

@media screen and (min-width: 1200px) {
	.prev-next {
		width: 385px;
	}

	.content {
		height: 600px;
	}

	.content-main {
		height: 550px;
	}
}

.content {
	padding: 0 20px 0 20px;
}

.content-main {
	padding-right: 12px;
}

.step-btn {
	width: 120px !important;
}

.round-btn {
	display: block !important;
	border-radius: 20px !important;
	background-color: transparent !important;
}

.labels {
	font-weight: 700 !important;
}

.input-hidden {
	position: absolute;
	left: -9999px;
}

input[type=radio]:checked+label>img {
	border: 1px solid #fff;
	box-shadow: 0 0 3px 3px #090;
}

/* Stuff after this is only to make things more pretty */
input[type=radio]+label>img {
	border: 1px dashed #444;
	width: 150px;
	height: 150px;
	transition: 500ms all;
}

input[type=radio]:checked+label>img {
	transform: rotateZ(-10deg) rotateX(10deg);
}

.moreInfo {
	font-size: 12px;
	color: gray;
	cursor: pointer;
}

.styleImage {
	width: 100%;
	/* height:150px; */
}

.styleImage:hover {
	opacity: 1 !important;
}

.styleImage:hover {
	cursor: pointer;
}

.sideInfills {
	width: 100%;
	/* height:150px; */
}

.sideInfills:hover {
	opacity: 1 !important;
}

.sideInfills:hover {
	cursor: pointer;
}

.add-label {
	position: relative;
}

.add-new {
	position: absolute;
	bottom: 5%;
	right: 25%;
	width: 25px;
	height: 25px;
}

.refresh {
	position: absolute;
	bottom: 5%;
	right: 5%;
	width: 25px;
	height: 25px;
}

.add-new:hover {
	cursor: pointer;
	fill: greenyellow;
	transform: scale(1.2);
}

.refresh:hover {
	cursor: pointer;
	fill: greenyellow !important;
	transform: scale(1.2);
}

.colorPicker {
	display: "inline-block";
	justify-content: "space-around";
}

.base-column {
	padding: 10px !important;
	color: black !important;
}

.base-column:hover {
	cursor: pointer;
}

.base-column-edit {
	background-color: lightgray !important;
}

/* .column-box {
  text-align: center;
} */
.add-column-btn {
	width: 100%;
}

.canvas {
	background-color: #e5faff !important;
	/* height: '100vh' !important; */
}

.canvas-1 {
	background-color: #e5faff !important;
	height: '100vh' !important;
}

.loader-container {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	background: white;
	z-index: 1;
}

.spinner {
	width: 64px;
	height: 64px;
	border: 8px solid;
	border-color: #3d5af1 transparent #3d5af1 transparent;
	border-radius: 50%;
	animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.border-right-radius-0 {
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
}

.border-left-radius-0 {
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
}

input#number {
	text-align: center;
	border: none;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	margin: 0px;
	width: 40px;
	height: 40px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input#number:focus-visible {
	outline: 0 !important;
	/* box-shadow: 0 0 0 0.15rem rgba(211,211,211,.5)!important; */
}

.base-columns>div>svg {
	fill: #31d84a !important;
	color: white;
	margin-right: 5px;
	height: 22px;
	width: 22px;
}

.base-columns>div>b>svg {
	float: right;
	line-height: 100%;
	/* height: 22px;
  width: 22px; */
}

.trash {
	fill: red;
	margin-left: 15px;
}

.selectedColumn {
	position: absolute;
	z-index: 999;
	transition: 0.3s ease-in-out;
}

.column-box {
	transition: 0.3s ease-in-out;
}

.hide-column {
	transform: translateX(-500px);
}

.show-column {
	transform: translateX(0);
}

.length-info {
	font-size: 10px;
	border-radius: 10px;
	width: 70px;
	text-align: center;
	padding: 0 10px;
	background-color: white;
	color: black;
	opacity: 0.7;
	transform: translateX(-50%) translateY(-50%);
}

.bashend {
	font-size: 10px;

	width: 10px;
	height: 10px;
	border-radius: 10px;
	transform: translateX(-5px);
	transform: translateY(-5px);
	background-color: white;
	opacity: 1;
}

.colortitle {
	margin-top: 4px;
	color: white;
	text-align: right;
	padding: 4px 6px;
	background-color: #002a4d;
}

.toggle-sidebar {
	color: white !important;
	cursor: pointer;
	background-color: #002853;
	right: -40px;
	width: 25px;
	height: 100px;
	line-height: 100px;
	top: 20%;
	font-size: 22px;
	position: absolute;
	border: 1px solid gray;
}

.toggle-sidebar>svg {
	fill: white !important;
}

.side-info {
	padding: 3px;
	display: flex;
	font-size: 12px;
	align-items: center;
	text-align: center;
	vertical-align: middle;
	justify-content: center;
	width: 70px;
	border-radius: 10px;
	background-color: white;
	color: black;
	opacity: 0.7;
	transform: translateX(-50%) translateY(-50%);
}

.side-info:hover {
	cursor: pointer;
	background-color: #002853;
	color: white;
}

.visible-info {
	padding: 5px;
	display: flex;
	font-size: 12px;
	align-items: center;
	text-align: center;
	vertical-align: middle;
	justify-content: center;
	border-radius: 50%;
	background-color: white;
	color: black;
	opacity: 0.7;
	transform: translateX(-50%) translateY(-50%);
}

.visible-info:hover {
	cursor: pointer;
	background-color: #002853;
	color: white;
}

.side-info-subsystem {
	width: 30px;
}

.overview {
	overflow-y: auto;
	overflow-x: hidden;
}

.overview::-webkit-scrollbar {
	background: #ddd;
	width: 5px;
}

.overview::-webkit-scrollbar-track {
	background: #ddd;
}

.overview::-webkit-scrollbar-thumb {
	background: #000c3a;
}

.overview::-webkit-scrollbar-thumb:hover {
	background: black;
}

.edit {
	cursor: pointer;
}

@media screen and (max-width: 576px) {
	.overview {
		height: 380px;
	}

	.canvas {
		height: 30vh !important;
		/* position: fixed !important; */
		z-index: 10;
	}
}

@media screen and (min-width: 576px) {
	.overview {
		height: 390px;
	}

	.canvas {
		height: 30vh !important;
	}
}

@media screen and (min-width: 768px) {
	.overview {
		height: 390px;
	}

	.canvas {
		height: calc(100vh - 122.75px) !important;
	}
}

@media screen and (min-width: 960px) {
	.overview {
		height: 35vh;
	}

	.canvas {
		height: calc(100vh - 122.75px) !important;
	}
}

@media screen and (min-width: 1200px) {
	.overview {
		height: 42vh;
	}

	.overview-order {
		height: 56vh;
	}

	.canvas {
		/* height: 100vh !important; */
		height: calc(100vh - 122.75px) !important;
	}
}

@media screen and (min-width: 1440px) {
	.overview {
		height: 52vh;
	}

	.overview-order {
		height: 70vh;
	}

	.canvas {
		/* height: 100vh !important; */
		height: calc(100vh - 122.75px) !important;
	}
}

@media screen and (min-width: 1601px) {
	.overview {
		height: 64vh;
	}

	.overview-order {
		height: 74vh;
	}
}

/* .css-zow5z4-MuiGrid-root {
  margin-top: 0 !important;

}
.css-zow5z4-MuiGrid-root>.MuiGrid-item {
  padding-top: 0 !important;
} */

/* .overview>div>div {
  margin-top: 0 !important;

  

}

.overview>div>div>div {
  padding-top: 0 !important;
} */

.form-field {
	width: 30ch;
}

.form-field-full {
	width: 61ch;
}

#success_msg {
	margin-top: 20px;
	color: green;
}

#err_msg {
	margin-top: 20px;
	color: red;
}

.logo {
	cursor: pointer;
}

/*Filter styles*/
.saturate {
	filter: saturate(3);
}

.grayscale {
	filter: grayscale(100%);
}

.contrast {
	filter: contrast(160%);
}

.brightness {
	filter: brightness(0.25);
}

.blur {
	filter: blur(3px);
}

.invert {
	filter: invert(100%);
}

.sepia {
	filter: sepia(100%);
}

.huerotate {
	filter: hue-rotate(180deg);
}

.rss.opacity {
	filter: opacity(50%);
}

.demo-select-info {
	position: relative;
}

.thankyou {
	width: 30px;
	height: 30px;
	position: absolute;
	right: 40px;
	top: 15px;
	z-index: 2;
}

* {
	font-family: 'Roboto Condensed' !important;
}

.toolbar {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 99;
}

.top-toolbar {
	/* background-color:#ffffff; */
	position: absolute;
	top: 25px;
	right: 0px;
	z-index: 99;
	/* border-radius: 20px; */
	padding: 3px 0px 3px 25px;
}

.top-toolbar svg {
	margin-right: 30px;
	color: dodgerblue;
	opacity: 0.5;
}

.top-toolbar svg:hover {
	cursor: pointer;
	opacity: 1;
}

.model-info {
	position: absolute;
	top: 25px;
	left: 80px;
	z-index: 99;
	/* border-radius: 20px; */
	padding: 3px 0px 3px 25px;
}

.circle-picker {
	width: 100% !important;
}

.circle-select {
	text-align: center;
	border-radius: 50%;
	display: inline-block;
	width: 45px;
	height: 45px;
	line-height: 45px;
	margin: 5px;
	border: 1px solid #7d7c7d;
	transition: all 200ms linear;
	font-size: 12px;
	cursor: pointer;
}

.circle-select-small {
	text-align: center;
	border-radius: 50%;
	display: inline-block;
	width: 35px;
	height: 35px;
	line-height: 35px;
	margin: 5px;
	border: 1px solid #7d7c7d;
	transition: all 200ms linear;
	font-size: 5px;
	cursor: pointer;
}

.circle-selected {
	border: 3.5px solid #7aae75;
}

.circle-selected:hover {
	border: 1px solid #7aae75;
}

.circle-select:hover {
	transform: scale(1.8);
	font-size: 9px;
	text-shadow: 1px 1px rgb(0 0 0 / 30%);
}

.circle-select-small:hover {
	transform: scale(1.8);
	font-size: 7px;
	text-shadow: 1px 1px rgb(0 0 0 / 30%);
}

.circle-select .circle-ral {
	display: inline-block;
	font-family: "brandon-grotesque", sans-serif;
	text-align: center;
	text-shadow: 1px 1px rgb(0 0 0 / 30%);
	vertical-align: middle;
	line-height: 6px;
	transform: translateY(-50%);
	opacity: 1;
}

.circle-select-texture {
	color: rgba(0, 0, 0, 0);
}

.circle-select:hover .circle-select-texture {
	color: rgba(255, 255, 255, 1);
}

.circle-select-small:hover .circle-select-texture {
	color: rgba(255, 255, 255, 1);
}

/* .circle-select:hover .circle-ral {
  opacity: 1;
} */

.clearfix {
	clear: both;
}

.circle-div {
	padding-left: 20px;
}

.color-white {
	color: white;
}

.color-black {
	color: black;
}

.preview {
	z-index: 99;
	position: absolute;
	bottom: 5%;
	right: 0;
	background-color: white;
	width: 300px;
	padding: 10px;
}

.MuiBadge-badge {
	height: 16px !important;
	min-width: 16px !important;
	border-radius: 8px !important;
	font-size: 0.6rem !important;
}

.order-basket {
	margin-right: 0px !important;
	color: white;
}

.action-button {
	cursor: pointer;
}

.sidebar {
	opacity: 1;
	transition: 0.3s all ease;
	width: 100%;
}

.sidebar-closed {
	overflow: hidden;
	transition: 0.3s all ease;
	/* opacity: 0; */
	max-width: 0;
	max-height: 0;
}

.content-canvas {
	position: relative;
	transition: 0.3s all ease;
	width: 100%;
}

.content-max {
	position: relative;
	transition: 0.3s all ease;
	width: 100%;
}

@media screen and (max-width: 576px) {
	.sidebar {
		width: 100%;
	}

	.content-canvas {
		width: 100%;
	}

	.content-max {
		width: 100%;
	}
}

@media screen and (min-width: 576px) {
	.sidebar {
		width: 100%;
	}

	.content-canvas {
		width: 100%;
	}

	.content-max {
		width: 100%;
	}
}

@media screen and (min-width: 768px) {
	.sidebar {
		width: 100%;
	}

	.content-canvas {
		width: 100%;
	}

	.content-max {
		width: 100%;
	}
}

@media screen and (max-width: 769px) {
	.sidebar {
		width: 100%;
	}

	.content-canvas {
		width: 100%;
	}

	.content-max {
		width: 100%;
	}
}

@media screen and (min-width: 960px) {
	.sidebar {
		width: 100%;
	}

	.content-canvas {
		width: 100%;
	}

	.content-max {
		width: 100%;
	}
}

@media screen and (min-width: 1200px) {
	.sidebar {
		width: 25%;
	}

	.content-canvas {
		width: 75%;
	}

	.content-max {
		width: 100%;
	}
}

.dimension-input .MuiInputBase-input {
	text-align: center !important;
}

.overview {
	padding-right: 40px !important;
}

body::-webkit-scrollbar {
	background: #ddd;
	width: 0px;
}

body::-webkit-scrollbar-track {
	background: #ddd;
}

body::-webkit-scrollbar-thumb {
	background: #000c3a;
}

body::-webkit-scrollbar-thumb:hover {
	background: black;
}

.MuiDialogContent-root::-webkit-scrollbar {
	background: #ddd;
	width: 1px;
}

.MuiDialogContent-root::-webkit-scrollbar-track {
	background: #ddd;
}

.MuiDialogContent-root::-webkit-scrollbar-thumb {
	background: #000c3a;
}

.MuiDialogContent-root::-webkit-scrollbar-thumb:hover {
	background: black;
}

button {
	box-shadow: none !important;
	border-radius: 2px !important;
}

.reset-default {
	display: inline-flex;
	text-align: center;
	color: black;
}

.reset-default:hover {
	cursor: pointer;
	color: gray;
}

.reset-default span {
	margin-right: 10px;
}

.reset-default svg {
	height: 23px;
	width: 23px;
}

.svg-2 {
	fill: none !important;
}

.polyurethan {
	width: 19px;
	height: 19px;
}

.selected .svg-1 path {
	fill: white;
}

.selected .svg-2 path {
	stroke: white;
}

.selected .polyurethan {
	filter: invert(100%);
}

.tabs {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

@media screen and (max-width: 768px) {
	.canvas>div {
		width: 105% !important;
		position: fixed !important;
		height: 30% !important;
	}

	.canvas {
		position: relative;
	}
}

.center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 10px;
}

.dark-svg path {
	stroke: white;
}

div::-webkit-scrollbar {
	background: #ddd;
	width: 5px;
}

div::-webkit-scrollbar-track {
	background: #ddd;
}

div::-webkit-scrollbar-thumb {
	background: #000c3a;
}

div::-webkit-scrollbar-thumb:hover {
	background: black;
}

.small-logo {
	width: 200px !important;
}

.canvas-2 {
	background-color: #e5faff !important;
	height: 100vh !important;
}

/* LOGIN */
.app-container {
	position: relative;
}
  
.background-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-attachment: scroll; 
	z-index: -1;
}
.background-image img {
	width: 100%;
	height: auto;
}

.page-content {
	position: relative;
	z-index: 1;
}

.content_box {
	height: 100vh;
	background: 'linear-gradient(180deg, rgba(117,150,174,1) 0%, rgba(221,227,232,1) 48%, rgba(255,255,255,1) 100%)';
	/* background-image: url('../img/logo/loginBackground.jpg'); */
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	background-position: center;
}

.topLeft-icon {
	position: absolute;
	top: 30px;
	left: 30px;
}

.shadow_effect {
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.466);
}

.logo-without-name {
	width: 120px;
	height: 120px;
	background-color: #fff;
	border-radius: 50%;
}

.image-preview {
	width: 200px;
	height: 200px;
	background-color: #fff;
	border-radius: 50%;
}

.auth_field,
.pure_field {
	height: 40px;
	width: 350px;
	padding: 0 10px;
	outline: none;
	color: #fff !important;
	border: 1px solid gray !important;
	backdrop-filter: blur(5px);
	background-color: #00000052 !important;
	transition: 0.3s;
}

.pure_field {
	transition: 0s;
}

.error_field {
	border: 1px solid red !important;
}

.auth_field:focus {
	background-color: #fff !important;
	color: gray !important;
	transition: 0.3s;
}

.arrow_right {
	height: 100%;
	width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: 0.3s;
	background-color: gray;
	border: none;
}

.submit_input {
	width: 350px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border: 1px solid gray;
	overflow: hidden;
	background-color: #00000052;
}

.submit_input input {
	width: 100%;
	border: none;
	background-color: transparent;
}

.circular_loader {
	color: white !important;
}

.circular_loader2 {
	color: rgba(0, 0, 0, 0.54) !important;
}

.alert_error {
	height: 40px;
	width: 350px;
	margin-top: 10px;
	background-color: #fff !important;
	color: red;
}

.alert_error svg {
	margin-right: 10px;
}

.forgot_password {
	width: 350px;
	font-size: 16px;
	color: #fff;
	cursor: pointer;
	margin-top: 5px;
	margin-bottom: 0px !important;
}

.forgot_password:hover {
	text-decoration: underline;
}

.register_img_box {
	width: 100%;
}

.register_img_box img {
	width: 150px;
}

@media (max-width:900px) {

	.submit_input,
	.pure_field,
	.forgot_password {
		width: 80% !important;
	}
}

.view-360 {
	font-size: 20px;
	color: white;
}

.error-message {
	color: red;
}

.view-icon {
	position: absolute;
	bottom: 3%;
	left: 3%;
	width: 80px;
	height: 80px;
}

.menu-icon {
	margin-left: 10px;
}

.user-balance {
	font-size: 18px;
}

.user-balance:hover {
	cursor: pointer;
}

.footer {
	/* background-color: #f1f1f1; */
}

.footer-left {
	/* border-right: 1px solid silver; */
	height: 350px;
	justify-content: left;
	align-items: left;
	padding-top: 10px;
	padding-bottom: 50px;
}

.footer-left p {
	font-size: 20px !important;
}

:is(.footer-right, .footer-center) p {
	font-size: 20px !important;
}

.footer-right {
	height: 350px;
	justify-content: left;
	align-items: left;
	text-align: left;
	padding-top: 10px;
	padding-bottom: 50px;
	padding-left: 10px;
}

.footer-center {
	height: 350px;
	justify-content: left;
	align-items: left;
	text-align: left;
	padding-top: 10px;
	padding-bottom: 50px;
	/* border-right: 1px solid silver; */
}

.about {
	min-height: 700px;
}

@keyframes zoom {
	0% {
		transform: scale(1);
		color: #757575;
		/* Initial color */
	}

	50% {
		transform: scale(1.2);
		color: #e00210;
		/* Intermediate color */
	}

	100% {
		transform: scale(1);
		color: #757575;
		/* Final color */
	}
}

.zoom-animation {
	animation: zoom 2s infinite;
}


@media(max-width:900px) {

	.footer-left,
	.footer-center,
	.footer-right {
		width: 100% !important;
		border: none;
		border-bottom: 1px solid silver;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.footer-left,
	.footer-center,
	.footer-right p {
		width: 100%;
	}
}

.canvas>div {
	z-index: 99 !important;
}

.animation-div {
	position: absolute !important;
	right: 0;
	z-index: 99;
	bottom: -75px;
	transform: 1s ease-in-out;
}

.visible-div {
	background-color: gray;
}

.truncate-lines {
	max-height: 3em;
	/* Adjust the height based on your requirements */
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	/* Adjust the line clamp value based on your requirements */
	-webkit-box-orient: vertical;
}

.section {
	background: 'linear-gradient(180deg, rgba(117,150,174,1) 0%, rgba(221,227,232,1) 48%, rgba(255,255,255,1) 100%)';
	/* background-image: url('../img/logo/loginBackground.jpg'); */
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}