@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url(./App.css);

body {
  margin: 0;
  font-family: 'Roboto Condensed';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto Condensed', monospace;
}

.phone-input-custom {
  --react-international-phone-border-radius: 2px;
  --react-international-phone-background-color: rgb(22 26 44 / var(--tw-bg-opacity));
  /* --react-international-phone-text-color: #fff; */
  --react-international-phone-selected-dropdown-item-background-color: rgb(
    22 26 44 / var(--tw-bg-opacity)
  );
  --react-international-phone-country-selector-background-color-hover: rgb(
    22 26 44 / var(--tw-bg-opacity)
  );
  /* Additional styles here */
}

.phone-input .react-international-phone-country-selector-button {
  border:none !important;
}

.phone-input .react-international-phone-input {
  background-color: var(--bg-input) !important;
  border: none !important;
  height: 50px !important;
  font-size: 14px !important;
  margin-left: 4px !important;
  width: 100% !important;
}

.phone-input .react-international-phone-country-selector-button {
  height: 100% !important;
  border: none !important;
  /* background-color: rgb(22 26 44 / var(--tw-bg-opacity)) !important; */
  padding-left: 4px !important;
}

