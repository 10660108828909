body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-Sidebar {
  display: flex;
  height: 100vh;
}

/* FADE ANIMATIONS */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.sidebar {
  width: 25%;
  opacity: 1;
  transition: 0.3s all ease;
}

.sidebar-closed {
  overflow: hidden;
  transition: 0.3s all ease;
  /* opacity: 0; */
  max-width: 0;
  max-height: 0;
}

.content-canvas {
  position: relative;
  transition: 0.3s all ease;
  width: 75%;
}

.content-max {
  position: relative;
  transition: 0.3s all ease;
  width: 100%;
}

@keyframes right-animate {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.floating-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

.floating-button {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #007bff;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.floating-button:hover {
  transform: translateY(-3px);
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 2000px;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-out;
}

.popup {
  position: fixed;
  width: 600px;
  height: 420px;
  background-color: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  animation: slideIn 0.3s ease-out;
}

.link-setting {
  color: inherit;
  text-decoration: none;
}

.link-setting:hover {
  text-decoration: underline;
}

.popup1 {
  position: fixed;
  width: 400px;
  height: 170px;
  background-color: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  animation: slideIn 0.3s ease-out;
}

.popup-right {
  right: 0;
}

.popup-left {
  left: 0;
}

.popup h2 {
  margin-top: 0;
}

.popup p {
  margin-bottom: 0;
}

.whatsnew-card {
  min-height: 250px;
  position: relative;
}

.whatsnew-btn {
  position: absolute !important;
  bottom: 0px;
  color: white;
}

.whatsnew-checkbox {
  position: absolute;
  bottom: 40px;
  right: 0%;
  width: 96.5%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
  }

  to {
    transform: translateY(0);
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.tooltip-arrow {
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))';
  position: absolute;
  top: -10px;
  right: 10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #fff;
}

@media screen and (max-width: 576px) {
  .popup {
    width: 330px;
    height: 470px;
  }

  .whatsnew-card {
    height: 300px;
  }
}

@media screen and (min-width: 576px) {
  .popup {
    width: 400px;
    height: 520px;
  }

  .whatsnew-card {
    height: 350px;
  }
}

@media screen and (min-width: 768px) {
  .popup {
    width: 600px;
    height: 420px;
  }

  .whatsnew-card {
    height: 250px;
  }
}

@media screen and (min-width: 900px) {
  .popup {
    width: 600px;
    height: 420px;
  }
}

@media screen and (min-width: 1200px) {
  .popup {
    width: 600px;
    height: 420px;
  }
}

.expire_date {
  text-decoration: underline;
  cursor: pointer;
}